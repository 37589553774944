import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import PageHeader from '../components/sections/headers/PageHeader'
import Layout from '../components/layouts/layout'
import Seo from '../components/seo'
import WhatWeDo from '../components/sections/WhatWeDo'
import SectionHeader from '../components/sections/headers/SectionHeader'
import { BsFacebook, BsFillEnvelopeFill, BsInstagram, BsTwitter } from '../utils/GetIcons'
import Reveal from '../utils/Reveal'
import { dataSocials } from '../data/dataSocials'
import SocialLink from '../components/common/buttons/SocialLink'

const SocialsList = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 2rem;
`

const SocialIcon = styled(SocialLink)`
  color: ${props => props.theme.primary700to300};
  margin: auto 0.5rem;
`

interface Props {
  data: any
  location: any
}

export default function AboutPage({ data, location }: Props): JSX.Element {
  const siteDescription =
    "We specialise in finding unique reasons to travel. Whether it's global cultural events, exciting backpacking routes, or amazing landmarks. We'll help you find a unique and memorable reason to travel."

  const getSocialIcon = (title: string): JSX.Element => {
    switch (title) {
      case 'Facebook':
        return <BsFacebook size={40} />
      case 'Instagram':
        return <BsInstagram size={40} />
      case 'Twitter':
        return <BsTwitter size={40} />
      case 'Email':
        return <BsFillEnvelopeFill size={40} />
      default:
        return <BsFacebook size={40} />
    }
  }

  const getSocial = (title: string, link: string): JSX.Element => {
    return (
      <SocialIcon key={title} id={title.toLowerCase()} href={link}>
        <span className="visually-hidden">{title}</span>
        {getSocialIcon(title)}
      </SocialIcon>
    )
  }

  return (
    <Layout location={location}>
      <PageHeader title={'About Us'} description={siteDescription} />
      <WhatWeDo useBigCards />
      <SectionHeader
        title={'Contact Us'}
        subtitle={'Follow us on social media to stay up to date with our latest content'}
      />
      <SocialsList>
        {dataSocials.map((social: any, index: number) => (
          <Reveal key={index} effect="fade-up" delay={index}>
            {getSocial(social.title, social.url)}
          </Reveal>
        ))}
      </SocialsList>
    </Layout>
  )
}

export function Head({ location }: any): JSX.Element {
  return (
    <Seo
      title="About Us"
      description={
        "Global cultural events, exciting backpacking routes, tales, tips, and advice. If you're looking for an excuse to travel, we've got you covered."
      }
      location={location}
    />
  )
}

export const pageQuery = graphql`
  query indexQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

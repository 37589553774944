import styled from 'styled-components'

export const CardDescription = styled.p`
  color: ${props => props.theme.black300greyscale200};
  display: -webkit-box;
  font: 400 var(--fontSize-0) / 1.2 var(--font-montserrat);
  margin: 0.5rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`

export default CardDescription
